/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Box, Flex } from "@theme-ui/components"
import { i18nContext, languages } from "../../context/i18nContext"
import Header from "../Header"
import { LanguageSwitcherContext } from "../../context/languageSwitcherContext"
import Hreflang from "../../components/Hreflang"
import Footer from "../Footer"
import Canonical from "../../components/Canonical"
import { MenuFooterType } from "../../context/menuFooterType"
import NewsletterForm from "../../components/NewsletterForm"
import { navigate } from "gatsby"

const Layout = ({
  children,
  locale,
  page = { type: null, pageTitle: null, currentPastMonth: null },
  i18nPaths,
}) => {

  const previousYear = new Date().getFullYear() === 2022 ? true : false
  const data = useStaticQuery(graphql`
    query SiteQuery {
      datoCmsSite: datoCmsSite {
        locale
      }
      gatsbySite: site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  const isBrowser = typeof window !== "undefined"

  if(previousYear && isBrowser)
  navigate("/oops")


  return (
    !previousYear &&
    <i18nContext.Provider
      value={languages[locale] || languages[data.datoCmsSite.locale]}
    >
      <LanguageSwitcherContext.Provider
        value={{ activeLocale: locale, paths: i18nPaths || [] }}
      >
        <MenuFooterType.Provider
          value={{ type: page.type, pageTitle: page.pageTitle, currentPastMonth: page.currentPastMonth }}
        >
          <Hreflang
            paths={i18nPaths}
            siteUrl={data.gatsbySite.siteMetadata.siteUrl}
          />
          <Canonical
            siteUrl={data.gatsbySite.siteMetadata.siteUrl}
            paths={i18nPaths}
          />
          <Flex
            sx={{
              flexDirection: "column",
              justifyContent: "space-between",
              minHeight: "100vh",
            }}
          >
            <Header />
            <Box as="main" sx={{ pt: page.type !== "month" && ["0",0] }}>
              {children}
            </Box>
            <NewsletterForm />
            <Footer />
          </Flex>
        </MenuFooterType.Provider>
      </LanguageSwitcherContext.Provider>
    </i18nContext.Provider>
  )
}

export default Layout
